import React, { FC, useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

import { FirebaseContext, UserContext } from './contexts';

const FirebaseApp: FC = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const db = firebase.firestore();
  const auth = firebase.auth();
  const functions = firebase.app().functions('asia-northeast1');
  if (process.env.NODE_ENV === 'development') {
    functions.useFunctionsEmulator(
      process.env.REACT_APP_LOCAL_API_HOST as string,
    );
  }

  const customLogin = async () => {
    try {
      if (!window.liff.isInClient()) return;
      const createCustomTokenOnCall = functions.httpsCallable(
        'createCustomToken',
      );
      const accessToken = window.liff.getAccessToken();
      const { data } = await createCustomTokenOnCall({ accessToken });

      await auth.signInWithCustomToken(data.token);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const unsubscribe = auth.onAuthStateChanged(async firebaseUser => {
    if (firebaseUser) {
      setUser(firebaseUser);
    } else {
      setUser(null);
    }
  });

  useEffect((): firebase.Unsubscribe => {
    customLogin();

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FirebaseContext.Provider value={{ db, auth, functions }}>
      <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
    </FirebaseContext.Provider>
  );
};

export default FirebaseApp;
