import React, { FC, ChangeEvent } from 'react';
import { DetailModel } from 'services/models/detailModel';
import { PdfType } from 'hooks/usePdf';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import * as Styled from './style';

export const Wrapper = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  overflow: scroll;
  position: fixed;
  top: 0;
  width: 100%;
`;
export const ScaleContainer = styled.div<{ scale: number; maxPage: number }>`
  height: ${({ maxPage }) => maxPage * 2}px;
  margin: 10px 0 0;
  transform: scale(${({ scale }) => scale});
  transform-origin: top left;
`;
export const HiddenContainer = styled.div`
  height: 0;
  overflow: hidden;
  width: 580px;
`;

type DownLoadEntryProps = {
  onInputChange(e: ChangeEvent): void;
  onButtonClick(): void;
};
export const DownLoadEntry: FC<DownLoadEntryProps> = ({
  onInputChange,
  onButtonClick,
}) => (
  <DownLoadEntryDiv>
    <div>
      <DownLoadEntryText>閲覧パスを入力してください</DownLoadEntryText>
      <DownLoadInput
        onChange={onInputChange}
        type="number"
        inputMode="numeric"
      />
      <DownloadButton
        type="button"
        onClick={onButtonClick}
        color="primary"
        variant="contained"
      >
        送信
      </DownloadButton>
    </div>
  </DownLoadEntryDiv>
);
const DownLoadEntryDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`;

const DownLoadEntryText = styled.p`
  font-size: 16px;
  line-height: 1;
  text-align: center;
`;

const DownLoadInput = styled.input`
  background-color: #fff;
  border: 1px solid #c1bebe;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin: 20px auto 0;
  padding: 8px 15px;
  width: 150px;
`;

const DownloadButton = styled(Button)`
  &&& {
    color: #fff;
    display: block;
    font-weight: bold;
    margin: 30px auto 0;
    width: 150px;
  }
`;

type ItemListProps = {
  items: DetailModel[];
  getHeight: boolean;
};
export const Items: FC<ItemListProps> = ({ items, getHeight }) => (
  <>
    {items.map(({ title, unitPrice, amount, unit }, index) => {
      if (typeof unitPrice !== 'number') return null;
      if (typeof amount !== 'number') return null;

      return (
        <Styled.ItemTr
          key={index.toString()}
          className={getHeight ? 'js-items' : ''}
        >
          <td>{title}</td>
          <td>{unitPrice.toLocaleString()}</td>
          <td>{unit}</td>
          <td>{amount.toLocaleString()}</td>
          <td>{(unitPrice * amount).toLocaleString()}</td>
        </Styled.ItemTr>
      );
    })}
  </>
);

export const Header: FC<{
  page: number;
  maxPage: number;
  date: string;
  documentType: 'invoice' | 'quotation';
}> = ({ documentType, page, maxPage, date }) => {
  return (
    <Styled.PDFHeader>
      {maxPage === 2 && (
        <Styled.PageNumber>
          {page}/{maxPage}
        </Styled.PageNumber>
      )}
      <Styled.Title>
        {documentType === 'invoice' ? '請求書' : '見積書'}
      </Styled.Title>
      <Styled.PublishDay>発行日:{date}</Styled.PublishDay>
    </Styled.PDFHeader>
  );
};
type FooterType = {
  note: string;
  subTotal: number;
  salesTax: number;
  withholdingTax: number;
  total: number;
};
export const Footer: FC<FooterType> = ({
  note,
  subTotal,
  salesTax,
  withholdingTax,
  total,
}) => {
  return (
    <Styled.Fotter className="js-footer">
      <Styled.Note>
        <Styled.NoteHedding>備考</Styled.NoteHedding>
        <Styled.NoteText>{note}</Styled.NoteText>
      </Styled.Note>
      <Styled.Price>
        <Styled.PriceList>
          <dt>小計</dt>
          <dd>¥ {subTotal.toLocaleString()}</dd>
        </Styled.PriceList>
        {!!salesTax && (
          <Styled.PriceList>
            <dt>消費税</dt>
            <dd>¥ {salesTax.toLocaleString()}</dd>
          </Styled.PriceList>
        )}
        {!!withholdingTax && (
          <Styled.PriceList>
            <dt>源泉徴収税</dt>
            <dd>¥ {withholdingTax.toLocaleString()}</dd>
          </Styled.PriceList>
        )}
        <Styled.PriceList>
          <dt>合計</dt>
          <dd>¥ {total.toLocaleString()}</dd>
        </Styled.PriceList>
      </Styled.Price>
    </Styled.Fotter>
  );
};

type PdfProps = Omit<PdfType, 'detailList'> & {
  firestPageDetail: DetailModel[];
  secondPageDetail: DetailModel[];
};

export const Pdf: FC<PdfProps> = ({
  documentType,
  effectiveDate,
  title,
  deadline,
  note,
  name,
  email,
  postCode,
  address,
  bankName,
  branchName,
  accountName,
  accountType,
  accountNumber,
  clientName,
  clientPostCode,
  clientAddress,
  subTotal,
  salesTax,
  withholdingTax,
  total,
  firestPageDetail,
  secondPageDetail,
}) => (
  <Styled.Pdf>
    <Styled.PdfPaper id="js-pdf-1">
      <Header
        documentType={documentType}
        page={1}
        maxPage={secondPageDetail.length ? 2 : 1}
        date={dayjs(effectiveDate).format('YYYY/MM/DD')}
      />
      <Styled.Hr />
      <Styled.PdfInfo>
        <Styled.Billing>
          <Styled.BillingAddress>
            <Styled.BillingAddressText>
              〒{clientPostCode}
            </Styled.BillingAddressText>
            <Styled.BillingAddressText>
              {clientAddress}
            </Styled.BillingAddressText>
            <Styled.BillingAddressText>
              <Styled.CompanyName>{clientName}</Styled.CompanyName>
              &nbsp;&nbsp;御中
            </Styled.BillingAddressText>
            <Styled.BillingAssking>
              下記のとおりご{documentType === 'invoice' ? '請求' : '見積'}
              申し上げます。
            </Styled.BillingAssking>
          </Styled.BillingAddress>
          <Styled.BillingPrice>
            <Styled.BilligTextWrap>
              <Styled.BillingPriceText>
                ご{documentType === 'invoice' ? '請求' : '見積'}金額
              </Styled.BillingPriceText>
              <Styled.BillingPriceText>（消費税込）</Styled.BillingPriceText>
            </Styled.BilligTextWrap>
            <Styled.BillingPriceYen>
              ¥{total.toLocaleString()}
            </Styled.BillingPriceYen>
          </Styled.BillingPrice>
          <Styled.BillingDeadline>
            <Styled.BillingDeadlinHeading>
              {documentType === 'invoice' ? 'お支払い期限' : '見積有効期限'}
            </Styled.BillingDeadlinHeading>
            <Styled.BillingDeadlinDay>
              {dayjs(deadline).format('YYYY/MM/DD')}
            </Styled.BillingDeadlinDay>
          </Styled.BillingDeadline>
        </Styled.Billing>
        <Styled.Ordering>
          <Styled.OrderingAddress>
            <Styled.OrderingAddressText>
              〒{postCode}
            </Styled.OrderingAddressText>
            <Styled.OrderingAddressText>{address}</Styled.OrderingAddressText>
            <Styled.OrderingAddressText>
              メールアドレス：<span>{email}</span>
            </Styled.OrderingAddressText>
            <Styled.OrderingAddressText>{name}</Styled.OrderingAddressText>
          </Styled.OrderingAddress>

          {documentType === 'invoice' && (
            <Styled.PayeeAccount>
              <Styled.PayeeHeading>振込先口座</Styled.PayeeHeading>
              <Styled.PayeeTextWrap>
                <Styled.PayeeText>
                  {bankName}&nbsp;
                  <Styled.PayeeBranch>{branchName}</Styled.PayeeBranch>
                </Styled.PayeeText>
                <Styled.PayeeText>
                  {accountType === 'savings' ? '普通' : '当座'}&nbsp;
                  {accountNumber}
                </Styled.PayeeText>
                <Styled.PayeeText>{accountName}</Styled.PayeeText>
              </Styled.PayeeTextWrap>
            </Styled.PayeeAccount>
          )}
        </Styled.Ordering>
      </Styled.PdfInfo>
      <Styled.Hr />
      <Styled.PayeeNote>
        <Styled.PayeeNoteText>
          {documentType === 'invoice'
            ? '※請求明細をご確認のうえ、お振込までに上記口座へお振込をお願いいたします。※お振込手数料はお客様にてご負担お願いいたします。'
            : '※納入予定日：貴社との合意の上決定とする'}
        </Styled.PayeeNoteText>
      </Styled.PayeeNote>
      <Styled.WorkTitle>
        <Styled.WorkTitleHeading>件名</Styled.WorkTitleHeading>
        <Styled.WorkTitleText>{title}</Styled.WorkTitleText>
      </Styled.WorkTitle>
      <Styled.TableWrap>
        <Styled.TableHr />
        <Styled.Table>
          <tbody>
            <tr>
              <th>品目</th>
              <th>数量</th>
              <th>単位</th>
              <th>単価</th>
              <th>金額</th>
            </tr>
            <Items items={firestPageDetail} getHeight={false} />
          </tbody>
        </Styled.Table>
      </Styled.TableWrap>

      {!secondPageDetail.length && (
        <Footer
          note={note}
          subTotal={subTotal}
          salesTax={salesTax}
          withholdingTax={withholdingTax}
          total={total}
        />
      )}
    </Styled.PdfPaper>
    {!!secondPageDetail.length && (
      <>
        <div style={{ height: '50px', backgroundColor: '#fafafa' }} />
        <Styled.PdfPaper id="js-pdf-2">
          <Header
            documentType={documentType}
            page={2}
            maxPage={2}
            date={dayjs(effectiveDate).format('YYYY/MM/DD')}
          />
          <Styled.Hr />
          <Styled.TableWrap>
            <Styled.TableHr />
            <Styled.Table>
              <tbody>
                <tr>
                  <th>品目</th>
                  <th>数量</th>
                  <th>単位</th>
                  <th>単価</th>
                  <th>金額</th>
                </tr>
                <Items items={secondPageDetail} getHeight={false} />
              </tbody>
            </Styled.Table>
          </Styled.TableWrap>
          <Footer
            note={note}
            subTotal={subTotal}
            salesTax={salesTax}
            withholdingTax={withholdingTax}
            total={total}
          />
        </Styled.PdfPaper>
      </>
    )}
  </Styled.Pdf>
);

export const ItemList: FC<ItemListProps> = ({ items, getHeight }) => {
  return (
    <Styled.HiddenItemList>
      <table>
        <tbody>
          <Items items={items} getHeight={getHeight} />
        </tbody>
      </table>
    </Styled.HiddenItemList>
  );
};
