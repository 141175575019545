const paths = {
  home: '/',
  user: '/user',
  newPayee: '/user/new-payee',
  clients: '/clients',
  newClient: '/clients/new-client',
  editClient: '/clients/edit-client/:id',
  documents: '/documents/:documentType(invoice|quotation)',
  newInvoice: '/documents/new-invoice',
  newQuotation: '/documents/new-quotation',
  editDocument: '/documents/edit-document/:id',
  signin: '/signin',
  pdf: '/pdf/:id',
};

export default paths;
