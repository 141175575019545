import { firestore } from 'firebase/app';

export type ClientSideUserModel = {
  id?: string;
  name: string;
  email: string;
  postCode: string;
  address: string;
  bankName: string;
  branchName: string;
  accountName: string;
  accountType: 'savings' | 'checking' | null;
  accountNumber: string;
};

export type ServerSideUserModel = ClientSideUserModel & {
  entryMode: 'invoice' | 'quotation' | null;
  isUserProfileFilled: boolean;
  isPayeeFilled: boolean;
  createdAt: firestore.Timestamp;
  updatedAt: firestore.Timestamp;
};

export const blankClientSideUser: ClientSideUserModel = {
  name: '',
  email: '',
  postCode: '',
  address: '',
  bankName: '',
  branchName: '',
  accountName: '',
  accountType: null,
  accountNumber: '',
};
