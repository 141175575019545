import React, { FC } from 'react';
import * as Presenter from './presenter';

export const LoadingContainer: FC<{ apiLoading: boolean }> = ({
  apiLoading,
  children,
}) => (
  <Presenter.Wrap apiLoading={apiLoading || undefined}>
    {children}
  </Presenter.Wrap>
);
