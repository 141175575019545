import React, { FC } from 'react';
import styled from '@emotion/styled';

export const DeleteButton: FC<{ onClick: () => void }> = ({
  children,
  onClick,
}) => (
  <DeleteButtonWrapDiv>
    <DeleteButtonButton type="button" onClick={onClick}>
      {children}
    </DeleteButtonButton>
  </DeleteButtonWrapDiv>
);

export const Wrap = styled.div<{ $inline?: true }>`
  padding: ${({ $inline }) => ($inline ? '2rem 0 2rem' : '2rem')};
`;

const DeleteButtonWrapDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 30px 0 20px;
`;

const DeleteButtonButton = styled.button`
  color: #d50000;
  font-size: 15px;
  padding: 0;
  text-decoration: underline;
`;
