/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useEffect, useRef, useState } from 'react';
import {
  ClientSideDocumentModel,
  blankDocument,
} from 'services/models/documentModel';
import { callFunctionName } from 'services/constants';
import { FirebaseContext, UserContext } from 'contexts';

export const useDocumentData = (documentId: string) => {
  const { user } = useContext(UserContext);
  const [documentData, setDocumentData] = useState<ClientSideDocumentModel>({
    ...blankDocument,
  });
  const [
    documentAddData,
    setDocumentAddData,
  ] = useState<ClientSideDocumentModel | null>(null);
  const [updateCallback, setUpdateCallback] = useState<{ callback?(): void }>(
    {},
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const registerDocumentData = (
    data: ClientSideDocumentModel,
    option: typeof updateCallback = {},
  ) => {
    setUpdateCallback(option);
    setDocumentAddData(data);
  };

  const firebaseRef = useRef(useContext(FirebaseContext));

  useEffect(() => {
    if (documentId && user) {
      const { functions } = firebaseRef.current;
      if (!functions) throw new Error('Firestore is not initialized');
      const fetchDocumentOnCall = functions.httpsCallable(
        callFunctionName.fetchDocument,
      );
      const load = async () => {
        setLoading(true);
        try {
          const {
            data,
          }: { data: ClientSideDocumentModel } = await fetchDocumentOnCall({
            id: documentId,
          });
          setDocumentData({ ...data, id: data.id });
          setError(null);
        } catch (err) {
          setError(err);
        }
        setLoading(false);
      };

      load();
    }
  }, [documentId, user]);

  useEffect(() => {
    if (!documentAddData) return;

    const post = async () => {
      setLoading(true);
      try {
        const { functions } = firebaseRef.current;
        if (!functions) throw new Error('Firestore is not initialized');
        const saveDocumentOnCall = functions.httpsCallable(
          callFunctionName.registerDocument,
        );
        const {
          data,
        }: { data: ClientSideDocumentModel } = await saveDocumentOnCall({
          document: documentAddData,
        });
        setDocumentData(data);
        setDocumentAddData(null);
        if (updateCallback.callback) {
          updateCallback.callback();
          setUpdateCallback({});
        }
        setError(null);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };

    post();
  }, [documentAddData, updateCallback]);

  return { documentData, loading, error, registerDocumentData };
};
