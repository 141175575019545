import React, { FC } from 'react';
import styled from '@emotion/styled';

type SelectProps = {
  noMargin?: true;
  label: string;
  error?: string;
};
export const SelectWrap: FC<SelectProps> = ({
  noMargin,
  label,
  error,
  children,
}) => {
  return (
    <Select noMargin={noMargin}>
      <SelectLabel>{label}</SelectLabel>
      {children}
      {!!error && <SelectError>{error}</SelectError>}
    </Select>
  );
};

export const ThinSelectWrap: FC = ({ children }) => {
  return <ThinSelect>{children}</ThinSelect>;
};

export const SelectBox = styled.select`
  background-color: #fff;
  border: 1px solid #c1bebe;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  padding: 15px;
  width: 100%;

  &::placeholder {
    color: #acacac;
  }
`;

export const ThinSelectBox = styled(SelectBox)`
  padding: 5px 30px 5px 10px;
`;

const Select = styled.div<{ noMargin?: true }>`
  margin: ${({ noMargin }) => (noMargin ? '0' : '20px 0 0')};
  position: relative;
`;

const ThinSelect = styled.div`
  position: relative;

  &::before {
    border-color: #000 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
  }
`;

const SelectLabel = styled.p`
  color: #505050;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 10px;
  position: relative;

  &::before {
    border-color: #000 transparent transparent transparent;
    border-style: solid;
    border-width: 10px 8px 0 8px;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    right: 20px;
    top: 46px;
    width: 0;
  }
`;

const SelectError = styled.p`
  color: #f44336;
  font-size: 12px;
  line-height: 1;
  margin: 5px 0 0;
`;
