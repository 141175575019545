import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ClientSideClientModel } from 'services/models/clientModel';
import { ClientEdit } from 'components/ClientEdit';
import { useClients } from 'hooks/useClients';
import { LoadingContainer } from 'components/LoadingContainer';
import paths from 'paths';

type PageProps = RouteComponentProps<{ id: string }>;

export const EditClient: FC<PageProps> = ({ match, history }) => {
  const {
    selectedClient,
    deleteClient,
    loading,
    error,
    registerClient,
  } = useClients(match.params.id);

  const onSubmit: (newClient: ClientSideClientModel) => void = newClient => {
    registerClient({
      client: { ...selectedClient, ...newClient },
      callback: () => {
        // eslint-disable-next-line no-alert
        alert('更新しました');
        history.push(paths.clients);
      },
    });
  };
  const onDelete = () => {
    if (
      // eslint-disable-next-line no-restricted-globals,no-alert
      !confirm(
        '本当に削除しますか？\n削除すると\nこのクライアントに紐づいた請求書・\n見積書が一切表示されなくなります。',
      )
    ) {
      return;
    }
    deleteClient({
      id: match.params.id,
      callback: () => {
        // eslint-disable-next-line no-alert
        alert('削除しました。');
        history.push(paths.clients);
      },
    });
  };

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-alert
      alert(error.message);
      window.liff.closeWindow();
    }
  }, [error]);

  return (
    <main>
      <LoadingContainer apiLoading={loading}>
        {selectedClient.clientName && (
          <ClientEdit
            onSubmit={onSubmit}
            client={selectedClient}
            onDelete={onDelete}
          />
        )}
      </LoadingContainer>
    </main>
  );
};
