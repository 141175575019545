/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';

const apiKey = process.env.REACT_APP_GEO_API_KEY;

export const usePostCode = () => {
  const [addressFromPostCode, setAddressFromPostCode] = useState('');
  const [postCode, setPostCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!postCode.match(/^\d{7}$/)) return;
    const load = async () => {
      setLoading(true);

      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${postCode}&language=ja&components=country:JP&key=${apiKey}`,
        );
        const data = await response.json();
        if (data.results) {
          const addressText = data.results[0].address_components
            .reverse()
            .filter(
              (el: { types: string[] }) =>
                !el.types.includes('postal_code') &&
                !el.types.includes('country'),
            )
            .map((item: { long_name: string }) => item.long_name)
            .join('');

          setAddressFromPostCode(addressText);
        } else {
          setAddressFromPostCode('');
        }
        setError(null);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };

    load();
  }, [postCode]);

  return { addressFromPostCode, setPostCode, loading, error };
};
