import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { EditDocument } from 'components/EditDocument';
import * as Presenter from './presenter';

type PageProps = RouteComponentProps<{ id: string }>;

export const NewInvoice: FC<PageProps> = ({ match }) => {
  return (
    <EditDocument documentId={match.params.id} initialDocumentType="invoice">
      <Presenter.LeadBlock />
    </EditDocument>
  );
};
