import React, { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useUserData } from 'hooks/useUserData';
import { usePostCode } from 'hooks/usePostCode';
import { ClientSideUserModel } from 'services/models/userModel';
import { LoadingContainer } from 'components/LoadingContainer';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  FormControl,
  TextFieldInput,
  TextFieldWrap,
} from 'components/Form';
import { Box } from '@material-ui/core';
import { FixedSubmitButton } from 'components/Button';
import { Heading, LeadParagraph } from 'components/Typography';
import { Spacer } from 'components/Util';

export const User: FC = () => {
  const { userData, loading, error, registerUserData } = useUserData();
  const {
    register,
    control,
    handleSubmit,
    errors,
    setValue,
    watch,
    reset,
    formState,
  } = useForm<ClientSideUserModel>({
    defaultValues: {
      ...userData,
    },
  });
  const { isDirty } = formState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit: (user: ClientSideUserModel) => void = user => {
    const newData = {
      ...userData,
      ...user,
    };
    registerUserData(newData, {
      callback: () => {
        // eslint-disable-next-line no-alert
        alert('更新しました');
        reset(newData);
      },
    });
  };
  const { addressFromPostCode, setPostCode } = usePostCode();
  const currentAdress = watch('address');

  useEffect(() => {
    if (!currentAdress) {
      setValue('address', addressFromPostCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, addressFromPostCode]);

  useEffect(() => {
    if (!userData) return;
    reset({
      ...userData,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (error) {
      /* eslint-disable-next-line no-alert */
      alert(error.message);
      window.liff.closeWindow();
    }
  }, [error]);

  return (
    <main>
      <LoadingContainer apiLoading={loading}>
        <Box p={4}>
          <Heading>プロフィール、振込先編集</Heading>
          <LeadParagraph>請求書や見積書の生成に利用します</LeadParagraph>

          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <FormControl component="fieldset" fullWidth>
              <TextFieldWrap
                label="お名前"
                subLabel="（法人の場合:法人名+担当者名）"
                error={errors.name?.message}
              >
                <TextFieldInput
                  name="name"
                  placeholder="令和 太郎"
                  maxLength={20}
                  ref={register({
                    required: {
                      value: true,
                      message: 'お名前を入力してください。',
                    },
                  })}
                />
              </TextFieldWrap>
              <TextFieldWrap
                label="メールアドレス"
                error={errors.email?.message}
              >
                <TextFieldInput
                  name="email"
                  maxLength={40}
                  ref={register({
                    required: {
                      value: true,
                      message: 'メールアドレスを入力してください。',
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: '正しいメールアドレスを入力してください。',
                    },
                  })}
                />
              </TextFieldWrap>
              <TextFieldWrap label="郵便番号" error={errors.postCode?.message}>
                <TextFieldInput
                  name="postCode"
                  placeholder="ハイフン不要"
                  maxLength={7}
                  inputMode="numeric"
                  onChange={e => {
                    setPostCode(e.target.value);
                  }}
                  ref={register({
                    required: {
                      value: true,
                      message: '郵便番号を入力してください。',
                    },
                    pattern: {
                      value: /^\d{7}$/,
                      message: '半角数字7桁でを入力してください。',
                    },
                  })}
                />
              </TextFieldWrap>
              <TextFieldWrap label="住所" error={errors.address?.message}>
                <TextFieldInput
                  name="address"
                  maxLength={50}
                  ref={register({
                    required: {
                      value: true,
                      message: '住所を入力してください。',
                    },
                  })}
                />
              </TextFieldWrap>
              <Spacer height={2} />
              <Heading>お客様の振込先編集</Heading>
              <Spacer height={0.5} />
              <TextFieldWrap label="銀行名" error={errors.bankName?.message}>
                <TextFieldInput
                  name="bankName"
                  placeholder="〇〇銀行"
                  maxLength={40}
                  ref={register({
                    required: {
                      value: true,
                      message: '銀行名を入力してください。',
                    },
                  })}
                />
              </TextFieldWrap>
              <TextFieldWrap label="支店名" error={errors.branchName?.message}>
                <TextFieldInput
                  name="branchName"
                  placeholder="〇〇支店"
                  maxLength={40}
                  ref={register({
                    required: {
                      value: true,
                      message: '支店名を入力してください。',
                    },
                  })}
                />
              </TextFieldWrap>
              <TextFieldWrap
                label="口座名義（カタカナ）"
                error={errors.accountName?.message}
              >
                <TextFieldInput
                  name="accountName"
                  maxLength={30}
                  ref={register({
                    required: {
                      value: true,
                      message: '口座名義（カタカナ）を入力してください。',
                    },
                    pattern: {
                      // eslint-disable-next-line no-irregular-whitespace
                      value: /^[ァ-ヶー　]*$/,
                      message: '全角カタカナで入力してください。',
                    },
                  })}
                />
              </TextFieldWrap>
              <Spacer height={1.5} />
              <FormGroup>
                <Controller
                  name="accountType"
                  render={({ value, onChange }) => (
                    <RadioGroup
                      aria-label="accountType"
                      value={value}
                      row
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="savings"
                        control={<Radio color="primary" />}
                        label="普通"
                      />
                      <FormControlLabel
                        value="checking"
                        control={<Radio color="primary" />}
                        label="当座"
                      />
                    </RadioGroup>
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: '口座種別を指定してください。',
                    },
                  }}
                  control={control}
                  defaultValue={false}
                />
              </FormGroup>
              <TextFieldWrap
                label="口座番号"
                error={errors.accountNumber?.message}
              >
                <TextFieldInput
                  name="accountNumber"
                  inputMode="numeric"
                  placeholder="7桁で入力"
                  maxLength={7}
                  ref={register({
                    required: {
                      value: true,
                      message: '口座番号を入力してください。',
                    },
                    pattern: {
                      value: /^\d{7}$/,
                      message: '半角数字7桁で入力してください。',
                    },
                  })}
                />
              </TextFieldWrap>
            </FormControl>
            <FixedSubmitButton disabled={!isDirty}>更新</FixedSubmitButton>
          </form>
        </Box>
      </LoadingContainer>
    </main>
  );
};
