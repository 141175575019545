import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormControl, TextFieldWrap, TextFieldInput } from 'components/Form';
import { DetailModel } from 'services/models/detailModel';
import * as Presenter from './presenter';

const translateToNumberOrBlank = (value: string) => {
  const isNumber = value.match(/^\d+$/);

  return isNumber ? Number(value) : '';
};

type DetailFormProps = {
  handleDetailSubmit: (newValue: DetailModel) => void;
  typeText: string;
};

export const DetailForm: FC<DetailFormProps> = ({
  handleDetailSubmit,
  typeText,
}) => {
  const { register, handleSubmit, control, errors } = useForm<DetailModel>();

  return (
    <>
      <Presenter.DetailLeadBlock text={typeText} />
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleDetailSubmit)}
      >
        <FormControl component="fieldset" fullWidth>
          <TextFieldWrap label="品目" error={errors.title?.message}>
            <TextFieldInput
              placeholder="例：ロゴ製作費"
              maxLength={40}
              name="title"
              ref={register({
                required: {
                  value: true,
                  message: '品目を入力してください。',
                },
              })}
            />
          </TextFieldWrap>
          <TextFieldWrap label="単価" error={errors.unitPrice?.message}>
            <Controller
              name="unitPrice"
              render={({ value, onChange }) => (
                <TextFieldInput
                  type="tel"
                  inputMode="numeric"
                  placeholder="50,000"
                  maxLength={11}
                  value={value}
                  onChange={e => {
                    onChange(translateToNumberOrBlank(e.target.value));
                  }}
                />
              )}
              rules={{
                required: { value: true, message: '単価を入力してください。' },
                pattern: {
                  value: /^\d+$/,
                  message: '半角数字で入力してください。',
                },
              }}
              control={control}
              defaultValue=""
            />
          </TextFieldWrap>
          <TextFieldWrap label="数量" error={errors.amount?.message}>
            <Controller
              name="amount"
              render={({ value, onChange }) => (
                <TextFieldInput
                  type="tel"
                  inputMode="numeric"
                  placeholder="20"
                  maxLength={9}
                  value={value}
                  onChange={e => {
                    onChange(translateToNumberOrBlank(e.target.value));
                  }}
                />
              )}
              rules={{
                required: { value: true, message: '数量を入力してください。' },
                pattern: {
                  value: /^\d+$/,
                  message: '半角数字で入力してください。',
                },
              }}
              control={control}
              defaultValue=""
            />
          </TextFieldWrap>
          <TextFieldWrap label="単位" error={errors.unit?.message}>
            <TextFieldInput
              placeholder="式"
              maxLength={10}
              name="unit"
              ref={register({
                required: {
                  value: true,
                  message: '単位を入力してください。',
                },
              })}
            />
          </TextFieldWrap>
        </FormControl>
        <Presenter.AddButton type="submit">登録</Presenter.AddButton>
      </form>
    </>
  );
};
