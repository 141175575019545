import React, { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useUserData } from 'hooks/useUserData';
import { ClientSideUserModel } from 'services/models/userModel';
import { LoadingContainer } from 'components/LoadingContainer';

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  FormControl,
  TextFieldInput,
  TextFieldWrap,
} from 'components/Form';
import { Box } from '@material-ui/core';
import { FixedSubmitButton } from 'components/Button';
import { Heading, LeadParagraph, AttentionText } from 'components/Typography';
import { Spacer } from 'components/Util';

type Payee = Pick<
  ClientSideUserModel,
  'accountName' | 'accountNumber' | 'accountType' | 'bankName' | 'branchName'
>;

export const NewPayee: FC = () => {
  const { userData, loading, error, registerUserData } = useUserData();
  const { register, control, handleSubmit, errors, setValue } = useForm<Payee>({
    defaultValues: {
      ...userData,
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit: (payee: Payee) => void = payee => {
    registerUserData(
      {
        ...userData,
        ...payee,
      },
      {
        callback: window.liff.closeWindow,
      },
    );
  };

  useEffect(() => {
    if (!userData) return;
    setValue('accountName', userData.accountName);
    setValue('accountNumber', userData.accountNumber);
    setValue('accountType', userData.accountType);
    setValue('address', userData.address);
    setValue('bankName', userData.bankName);
    setValue('branchName', userData.branchName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-alert
      alert(error.message);
      window.liff.closeWindow();
    }
  }, [error]);

  return (
    <main>
      <LoadingContainer apiLoading={loading}>
        <Box p={4}>
          <Heading>お客様の振込先入力</Heading>
          <LeadParagraph>
            請求書の生成に利用します。
            <br />
            全項目<AttentionText>必須</AttentionText>になります。
            <br />
            お客様が給与を受け取る
            <br />
            口座情報を入力してください
          </LeadParagraph>

          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <FormControl component="fieldset" fullWidth>
              <TextFieldWrap label="銀行名" error={errors.bankName?.message}>
                <TextFieldInput
                  name="bankName"
                  placeholder="〇〇銀行"
                  maxLength={40}
                  ref={register({
                    required: {
                      value: true,
                      message: '銀行名を入力してください',
                    },
                  })}
                />
              </TextFieldWrap>
              <TextFieldWrap label="支店名" error={errors.branchName?.message}>
                <TextFieldInput
                  name="branchName"
                  placeholder="〇〇支店"
                  maxLength={40}
                  ref={register({
                    required: {
                      value: true,
                      message: '支店名を入力してください',
                    },
                  })}
                />
              </TextFieldWrap>
              <TextFieldWrap
                label="口座名義（カタカナ）"
                error={errors.accountName?.message}
              >
                <TextFieldInput
                  name="accountName"
                  maxLength={30}
                  ref={register({
                    required: {
                      value: true,
                      message: '口座名義（カタカナ）を入力してください',
                    },
                    pattern: {
                      // eslint-disable-next-line no-irregular-whitespace
                      value: /^[ァ-ヶー　]*$/,
                      message: '全角カタカナで入力してください',
                    },
                  })}
                />
              </TextFieldWrap>
              <Spacer height={1.5} />
              <FormGroup>
                <Controller
                  name="accountType"
                  render={({ value, onChange }) => (
                    <RadioGroup
                      aria-label="accountType"
                      value={value}
                      row
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="savings"
                        control={<Radio color="primary" />}
                        label="普通"
                      />
                      <FormControlLabel
                        value="checking"
                        control={<Radio color="primary" />}
                        label="当座"
                      />
                    </RadioGroup>
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: '口座種別を指定してください',
                    },
                  }}
                  control={control}
                  defaultValue={false}
                />
              </FormGroup>
              <TextFieldWrap
                label="口座番号"
                error={errors.accountNumber?.message}
              >
                <TextFieldInput
                  name="accountNumber"
                  inputMode="numeric"
                  placeholder="7桁で入力"
                  maxLength={7}
                  ref={register({
                    required: {
                      value: true,
                      message: '口座番号を入力してください。',
                    },
                    pattern: {
                      value: /^\d{7}$/,
                      message: '半角数字7桁で入力してください。',
                    },
                  })}
                />
              </TextFieldWrap>
            </FormControl>
            <FixedSubmitButton>更新</FixedSubmitButton>
          </form>
        </Box>
      </LoadingContainer>
    </main>
  );
};
