import React, { FC } from 'react';
import { AppBar as MuiAppBar, Toolbar, makeStyles } from '@material-ui/core';
import styled from '@emotion/styled';

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
}));

export const AppBar: FC = () => {
  const classes = useStyles();

  return (
    <>
      <MuiAppBar id="mui-app-bar">
        <Toolbar>
          <AppBarText>L-contract | 請求書・見積書作成</AppBarText>
        </Toolbar>
      </MuiAppBar>
      <div className={classes.offset} />
    </>
  );
};

const AppBarText = styled.h1`
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
`;
