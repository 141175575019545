export const collectionName = {
  users: 'users',
  clients: 'clients',
  documents: 'documents',
  taxRates: 'taxRates',
} as const;

export const callFunctionName = {
  fetchTaxRates: 'fetchTaxRates',
  fetchClientList: 'fetchClientList',
  registerClient: 'registerClient',
  deleteClient: 'deleteClient',
  fetchDocument: 'fetchDocument',
  fetchDocumentList: 'fetchDocumentList',
  registerDocument: 'registerDocument',
  deleteDocument: 'deleteDocument',
  fetchUser: 'fetchUser',
  registerUser: 'registerUser',
  fetchPdf: 'fetchPdf',
  bot: 'bot',
};
