import React, { FC } from 'react';
import styled from '@emotion/styled';

type TextFieldProps = {
  noMargin?: true;
  label: string;
  subLabel?: string;
  error?: string;
  thin?: true;
};
export const TextFieldWrap: FC<TextFieldProps> = ({
  noMargin,
  label,
  subLabel,
  error,
  children,
}) => {
  return (
    <TextField noMargin={noMargin}>
      <TextFieldLabel>
        {label}
        <span>{subLabel}</span>
      </TextFieldLabel>
      {children}
      {!!error && <TextFieldError>{error}</TextFieldError>}
    </TextField>
  );
};

export const ThinTextFieldWrap: FC<TextFieldProps> = ({
  noMargin,
  label,
  subLabel,
  error,
  children,
}) => {
  return (
    <ThinTextField noMargin={noMargin}>
      <ThinTextFieldLabel>
        {label}
        <span>{subLabel}</span>
      </ThinTextFieldLabel>
      {children}
      {!!error && <TextFieldError>{error}</TextFieldError>}
    </ThinTextField>
  );
};

export const TextFieldInput = styled.input`
  background-color: #fff;
  border: 1px solid #c1bebe;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  padding: 13px 15px;
  width: 100%;

  &::placeholder {
    color: #acacac;
  }
`;

export const ThinTextFieldInput = styled.input`
  background-color: #fff;
  border: 1px solid #c1bebe;
  font-size: 16px;
  line-height: 1;
  max-width: 161px;
  padding: 5px 10px;

  &::placeholder {
    color: #acacac;
  }
`;

export const TextFieldArea = styled.textarea`
  background-color: #fff;
  border: 1px solid #c1bebe;
  font-size: 16px;
  font-weight: 600;
  height: 130px;
  line-height: 1;
  padding: 15px;
  width: 100%;

  &::placeholder {
    color: #acacac;
  }
`;

const TextField = styled.div<{ noMargin?: true }>`
  margin: ${({ noMargin }) => (noMargin ? '0' : '20px 0 0')};
`;

const ThinTextField = styled.div<{ noMargin?: true }>`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: ${({ noMargin }) => (noMargin ? '0' : '20px 0 0')};
  margin: 20px 0 10px;
  position: relative;
`;

const TextFieldLabel = styled.p`
  color: #505050;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 10px;

  > span {
    font-size: 14px;
  }
`;

const ThinTextFieldLabel = styled.p`
  color: #505050;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  margin: 0 8px 0 0;
  position: relative;

  > span {
    font-size: 14px;
  }
`;

const TextFieldError = styled.p`
  color: #f44336;
  font-size: 12px;
  line-height: 1;
  margin: 5px 0 0;
`;
