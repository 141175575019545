import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Home from 'pages/Home';
import { AppBar } from 'components/AppBar';
import { User } from 'pages/User';
import { NewPayee } from 'pages/User/NewPayee';
import { NewClient } from 'pages/Clients/NewClient';
import { EditClient } from 'pages/Clients/EditClient';
import { Clients } from 'pages/Clients';
import { Documents } from 'pages/Documents';
import { NewInvoice } from 'pages/Documents/NewInvoice';
import { NewQuotation } from 'pages/Documents/NewQuotation';
import { EditDocument } from 'pages/Documents/EditDocument';
import { Pdf } from 'pages/Pdf';
import paths from './paths';

const App: FC = () => (
  <div>
    <Switch>
      <Route path={paths.home} exact />
      <Route path={paths.pdf} />
      <Route path="/(.+)" component={AppBar} />
    </Switch>
    <Switch>
      <Route path={paths.home} component={Home} exact />
      <Route path={paths.newPayee} component={NewPayee} />
      <Route path={paths.user} component={User} />
      <Route path={paths.editClient} component={EditClient} />
      <Route path={paths.newClient} component={NewClient} />
      <Route path={paths.clients} component={Clients} />
      <Route path={paths.newInvoice} component={NewInvoice} />
      <Route path={paths.newQuotation} component={NewQuotation} />
      <Route path={paths.editDocument} component={EditDocument} />
      <Route path={paths.documents} component={Documents} />
      <Route path={paths.pdf} component={Pdf} />
      <Redirect to={paths.home} />
    </Switch>
  </div>
);

export default App;
