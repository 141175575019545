/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useEffect, useRef, useState } from 'react';
import { ClientSideDocumentModel } from 'services/models/documentModel';
import { callFunctionName } from 'services/constants';
import { FirebaseContext, UserContext } from 'contexts';

type NoUndefinedField<T> = {
  [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>>;
};

export const useDocumentList = (
  documentType: NonNullable<ClientSideDocumentModel['documentType']>,
) => {
  const { user } = useContext(UserContext);
  const [documentList, setDocumentList] = useState<
    NoUndefinedField<ClientSideDocumentModel>[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const firebaseRef = useRef(useContext(FirebaseContext));

  useEffect(() => {
    if (!user) return;
    const { functions } = firebaseRef.current;
    if (!functions) throw new Error('Firestore is not initialized');
    const fetchDocumentOnCall = functions.httpsCallable(
      callFunctionName.fetchDocumentList,
    );
    const load = async () => {
      setLoading(true);
      try {
        const {
          data,
        }: {
          data: NoUndefinedField<ClientSideDocumentModel>[];
        } = await fetchDocumentOnCall({
          documentType,
        });
        setDocumentList(data);
        setError(null);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };

    load();
  }, [documentType, user]);

  return { documentList, loading, error };
};
