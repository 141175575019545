import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Select } from '@material-ui/core';
import { ClientSideDocumentModel } from 'services/models/documentModel';
import { Heading } from 'components/Typography';

export const DocumentsHeading: FC = () => <Heading>履歴一覧</Heading>;

export const EffectiveYear: FC = ({ children }) => (
  <EffectiveYearDiv>
    <EffectiveYearText>発行年</EffectiveYearText>
    {children}
  </EffectiveYearDiv>
);

export const DocumentsTab: FC<{
  documentType: NonNullable<ClientSideDocumentModel['documentType']>;
}> = ({ documentType }) => (
  <Tab>
    <TabLink to="/documents/invoice">
      <TabLinkText isActive={documentType === 'invoice'}>請求書</TabLinkText>
      {documentType === 'invoice' && <TabLinkBorder />}
    </TabLink>
    <TabLink to="/documents/quotation">
      <TabLinkText isActive={documentType !== 'invoice'}>見積書</TabLinkText>
      {documentType !== 'invoice' && <TabLinkBorder />}
    </TabLink>
  </Tab>
);

export const DocumentList: FC<{
  listItems: {
    id: string;
    text: string;
  }[];
}> = ({ listItems }) => {
  return (
    <ListUl>
      {listItems.map(({ id, text }) => (
        <li key={id}>
          {/* {[...text].map(charactor => (
            <CharactorSpan key={id + charactor}>{charactor}</CharactorSpan>
          ))} */}
          {text}
          <DetailLink to={`/documents/edit-document/${id}`}>
            詳細 &gt;
          </DetailLink>
        </li>
      ))}
    </ListUl>
  );
};

export const Main = styled.main`
  padding: 2rem 0;
`;

export const EffectiveSelect = styled(Select)`
  .MuiSelect-root {
    padding: 8px 32px 8px 16px;
  }
`;

export const Hr = styled.hr`
  border-top: 2px solid ccc;
  margin: 0;
`;

const EffectiveYearDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
`;

const EffectiveYearText = styled.span`
  font-size: 14px;
  margin: 0 0.5rem 0 0;
`;

const Tab = styled.div`
  display: flex;
  padding: 0 2rem;
  width: 100%;
`;

const TabLink = styled(Link)`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  margin: 0;
  position: relative;
  width: 50%;
`;

const TabLinkText = styled.span<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? '#000' : '#7c7c7c')};
`;
const TabLinkBorder = styled.span`
  border-bottom: 6px solid #01b801;
  bottom: -3px;
  content: '';
  display: block;
  left: 0;
  position: absolute;
  width: 100%;
`;

const ListUl = styled.ul`
  padding: 10px 20px;

  > li {
    font-size: 15px;
    line-height: 1.8;
    overflow: hidden;
    padding: 15px 0;
  }

  > li + li {
    border-top: 1px solid #ccc;
  }
`;

const DetailLink = styled(Link)`
  color: #005cc7;
  float: right;
  line-height: 1.8;
  position: relative;

  &::after {
    border-bottom: 1px solid #005cc7;
    bottom: 2px;
    content: '';
    display: inline-block;
    left: 0;
    position: absolute;
    width: 100%;
  }
`;
