import { firestore } from 'firebase/app';
import dayjs from 'dayjs';
import { DetailModel } from './detailModel';

export type ClientSideDocumentModel = {
  id?: string;
  clientId: string;
  documentType: 'quotation' | 'invoice' | null;
  effectiveDate: number;
  title: string;
  deadline: number;
  note: string;
  detailList: DetailModel[];
  taxRate: number;
  taxable: boolean;
  withholding: boolean;
};

export const blankDocument: ClientSideDocumentModel = {
  clientId: '',
  documentType: null,
  effectiveDate: Date.now(),
  title: '',
  deadline: dayjs()
    .add(1, 'month')
    .valueOf(),
  note: '',
  detailList: [],
  taxRate: 0.1,
  taxable: false,
  withholding: false,
};

export type ServerSideDocumentModel = ClientSideDocumentModel & {
  userId: string;
  password: number;
  createdAt: firestore.Timestamp;
  updatedAt: firestore.Timestamp;
};
