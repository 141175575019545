import React, { FC } from 'react';
import { Heading, LeadParagraph } from 'components/Typography';
import { ArrowBackIos, PersonAdd } from '@material-ui/icons';
import styled from '@emotion/styled';
import { rgba } from 'polished';
import { Button, ButtonProps } from '@material-ui/core';
import { Select } from 'components/Form';

export const LeadBlock: FC<{ text: string }> = ({ text }) => {
  return (
    <>
      <Heading>詳細</Heading>
      <LeadParagraph>{text}情報を確認してください</LeadParagraph>
    </>
  );
};

export const AddButton: FC<ButtonProps> = ({
  children,
  onClick,
  type,
  disabled,
}) => (
  <AddButtonWrap>
    <AddButtonBtn
      fullWidth
      onClick={onClick}
      type={type || 'button'}
      color="primary"
      size="large"
      variant="contained"
      disabled={disabled}
    >
      {children}
    </AddButtonBtn>
  </AddButtonWrap>
);

export const ClientWrap: FC<{
  onClickAddClient: () => void;
}> = ({ children, onClickAddClient }) => (
  <ClientWrapDiv>
    {children}
    <AddClientIcon onClick={onClickAddClient} />
  </ClientWrapDiv>
);

type FixedSubmitButtonProps = {
  form: string;
  disabled: boolean;
};
export const Submit: FC<FixedSubmitButtonProps> = ({
  children,
  form,
  disabled,
}) => (
  <SubmitWrap>
    <SubmitButton
      form={form}
      fullWidth
      type="submit"
      color="primary"
      size="large"
      variant="contained"
      disabled={disabled}
    >
      {children}
    </SubmitButton>
  </SubmitWrap>
);

export const DetailLeadBlock: FC<{ text: string }> = ({ text }) => {
  return (
    <>
      <Heading>明細入力</Heading>
      <LeadParagraph>{text}に記載する明細を入力してください</LeadParagraph>
    </>
  );
};

export const ArrowBack: FC<{ onClick: () => void }> = ({ onClick }) => (
  <ArrowBackWrap onClick={onClick}>
    <ArrowBackIos />
  </ArrowBackWrap>
);

export const CheckboxWrap: FC = ({ children }) => (
  <CheckboxWrapDiv>{children}</CheckboxWrapDiv>
);

type TotalAmountProps = {
  subTotal: number;
  salesTax: number;
  withholdingTax: number;
  total: number;
};
export const TotalAmount: FC<TotalAmountProps> = ({
  subTotal,
  salesTax,
  withholdingTax,
  total,
}) => (
  <TotalAmountDl>
    <dt>小計</dt>
    <dd>{subTotal.toLocaleString()}円</dd>
    <dt>消費税</dt>
    <dd>{salesTax.toLocaleString()}円</dd>
    <dt>源泉徴収税</dt>
    <dd>{withholdingTax.toLocaleString()}円</dd>
    <dt>合計</dt>
    <dd>{total.toLocaleString()}円</dd>
  </TotalAmountDl>
);

export const Main = styled.main`
  overflow-x: hidden;
  padding: 2rem 2rem 7rem;
  width: 100%;
`;

export const AddClientText = styled.button`
  color: #01b801;
  font-size: 16px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const ClientSelect = styled(Select)`
  width: 100%;
`;

export const TaxColumn = styled.div`
  .MuiFormControlLabel-root {
    width: 30%;
  }
  align-items: center;
  display: flex;
`;

export const TaxSelect = styled.select<{ isShow: boolean }>`
  background-color: #fff;
  border: 1px solid #c1bebe;
  display: ${({ isShow }) => (isShow ? 'inline' : 'none')};
  font-size: 16px;
  font-weight: 600;
  height: 30px;
  line-height: 1;
  padding: 15px;
  padding: 0 10px;
  text-align: right;
  width: 70px;
`;

export const DetailHeading = styled.p`
  color: #505050;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  margin: 30px 0 10px;
`;

const ArrowBackWrap = styled.div`
  left: 1rem;
  position: absolute;
  top: 1rem;
  z-index: 1;
`;
const AddButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0 30px;
`;

const AddButtonBtn = styled(Button)`
  &&& {
    font-weight: bold;
  }
`;
const ClientWrapDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 1rem;
`;

const AddClientIcon = styled(PersonAdd)`
  color: #c34a4a;
  font-size: 2.5rem;
  margin: 0 1rem;
`;

const SubmitWrap = styled.div`
  background-color: ${rgba('#fff', 0.8)};
  bottom: 0;
  justify-content: center;
  left: 0;
  padding: 15px 30px 40px;
  position: fixed;
  width: 100%;
`;

const SubmitButton = styled(Button)`
  &&& {
    font-weight: bold;
  }
`;

const CheckboxWrapDiv = styled.div`
  margin: 20px 0 20px;

  .MuiFormControlLabel-root {
    width: 100%;
  }
`;

const TotalAmountDl = styled.dl`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0 0 auto;
  width: 220px;

  & > dt {
    display: block;
    margin: 12px 0 0;
    width: 40%;
  }

  & > dd {
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    margin: 12px 0 0;
    text-align: right;
    width: 60%;
  }

  & > dt:last-of-type {
    font-weight: bold;
  }

  & > dd:last-of-type {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: bold;
  }
`;
