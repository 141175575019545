/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useEffect, useRef, useState } from 'react';
import {
  ClientSideUserModel,
  blankClientSideUser,
} from 'services/models/userModel';
import { callFunctionName } from 'services/constants';
import { FirebaseContext, UserContext } from 'contexts';

export const useUserData = () => {
  const { user } = useContext(UserContext);
  const [userData, setUserData] = useState<ClientSideUserModel>({
    ...blankClientSideUser,
  });
  const [
    userUpdateData,
    setUserUpdateData,
  ] = useState<ClientSideUserModel | null>(null);
  const [updateCallback, setUpdateCallback] = useState<{ callback?(): void }>(
    {},
  );

  const registerUserData = (
    data: ClientSideUserModel,
    option: typeof updateCallback = {},
  ) => {
    setUpdateCallback(option);
    setUserUpdateData(data);
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const firebaseRef = useRef(useContext(FirebaseContext));

  useEffect(() => {
    if (!user) return;
    const { functions } = firebaseRef.current;
    if (!functions) throw new Error('Firestore is not initialized');
    const fetchDocumentOnCall = functions.httpsCallable(
      callFunctionName.fetchUser,
    );
    const load = async () => {
      setLoading(true);
      try {
        const {
          data,
        }: { data: ClientSideUserModel } = await fetchDocumentOnCall();
        setUserData(data);
        setError(null);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };

    load();
  }, [user]);

  useEffect(() => {
    if (!userUpdateData) return;
    const post = async () => {
      setLoading(true);
      try {
        const { functions } = firebaseRef.current;
        if (!functions) throw new Error('Firestore is not initialized');
        const saveDocumentOnCall = functions.httpsCallable(
          callFunctionName.registerUser,
        );
        await saveDocumentOnCall({
          user: userUpdateData,
        });
        setError(null);
        setUserUpdateData(null);
        if (updateCallback.callback) {
          updateCallback.callback();
          setUpdateCallback({});
        }
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };

    post();
  }, [userUpdateData, updateCallback]);

  return { userData, loading, error, registerUserData };
};
