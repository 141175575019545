import React, { FC } from 'react';
import styled from '@emotion/styled';

type SpacerProps = {
  height?: number;
};

export const Spacer: FC<SpacerProps> = ({ height }) => (
  <SpacerDiv height={height} />
);

const SpacerDiv = styled.div<SpacerProps>`
  clear: both;
  height: ${({ height = 3 }) => height}rem;
  width: auto;
`;
