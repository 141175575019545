import React, { FC } from 'react';
import { Heading, LeadParagraph } from 'components/Typography';

export const LeadBlock: FC = () => {
  return (
    <>
      <Heading>見積書&nbsp;入力</Heading>
      <LeadParagraph>見積書情報を入力してください</LeadParagraph>
    </>
  );
};
