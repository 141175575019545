import React, { FC } from 'react';
import styled from '@emotion/styled';
import { FormControl as MuiFormControl } from '@material-ui/core';

export const FormControl: FC = props => <StyledFormControl {...props} />;

export const StyledFormControl = styled(MuiFormControl)`
  &&& {
    & > .MuiFormControl-root,
    & > .MuiFormGroup-root {
      /* margin: 1rem; */
    }
  }
`;
