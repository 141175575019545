/* eslint-disable react/destructuring-assignment */
import React, { FC } from 'react';
import {
  Select as MuiSelect,
  SelectProps,
  FormControl,
  InputLabel,
} from '@material-ui/core';

export {
  SelectWrap,
  SelectBox,
  ThinSelectWrap,
  ThinSelectBox,
} from './presenter';

export const Select: FC<SelectProps> = props => (
  <FormControl variant="outlined" className={props.className}>
    <InputLabel htmlFor={props?.inputProps?.id}>{props.label}</InputLabel>
    <MuiSelect {...props}>{props.children}</MuiSelect>
  </FormControl>
);
