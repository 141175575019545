import React, { FC } from 'react';
// import { List } from '@material-ui/core';
import { DetailModel } from 'services/models/detailModel';
import * as Presenter from './presenter';

type DetailListProps = {
  data: DetailModel[];
  onClickDeleteButton: (index: number) => void;
};

export const DetailList: FC<DetailListProps> = ({
  data,
  onClickDeleteButton,
}) => {
  return (
    <Presenter.List>
      {data.map(({ title, unitPrice, amount, unit }, index) => (
        <Presenter.DetailItem
          key={Math.random()}
          title={title}
          unitPrice={unitPrice}
          amount={amount}
          unit={unit}
          onClickDeleteButton={() => {
            onClickDeleteButton(index);
          }}
        />
      ))}
    </Presenter.List>
  );
};
