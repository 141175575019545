import React, { FC } from 'react';
import styled from '@emotion/styled';
import { rgba } from 'polished';
import { Button, ButtonProps } from '@material-ui/core';

export const FixedSubmitButton: FC<ButtonProps & { noHeight?: true }> = ({
  children,
  disabled,
  noHeight,
  onClick,
}) => (
  <>
    {!noHeight && <FixedSubmitDummyHight />}
    <FixedButtonWrap>
      <FixedSubmitButtonBtn
        type="submit"
        color="primary"
        size="large"
        variant="contained"
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </FixedSubmitButtonBtn>
    </FixedButtonWrap>
  </>
);

const FixedButtonWrap = styled.div`
  &&& {
    align-items: center;
    background-color: ${rgba('#fff', 0.8)};
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    padding: 15px 30px 40px;
    position: fixed;
    right: 0;
    width: 100%;
  }
`;

const FixedSubmitDummyHight = styled.div`
  height: 100px;
`;

const FixedSubmitButtonBtn = styled(Button)`
  &&& {
    font-weight: bold;
    width: 15rem;
  }
`;
