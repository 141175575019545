import React, { FC, useEffect } from 'react';
import { ClientSideClientModel } from 'services/models/clientModel';
import { ClientEdit } from 'components/ClientEdit';
import { useClients } from 'hooks/useClients';
import { LoadingContainer } from 'components/LoadingContainer';

export const NewClient: FC = () => {
  const { selectedClient, loading, error, registerClient } = useClients();

  const onSubmit: (newClient: ClientSideClientModel) => void = newClient => {
    registerClient({
      client: { ...selectedClient, ...newClient },
      callback: window.liff.closeWindow,
    });
  };

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-alert
      alert(error.message);
      window.liff.closeWindow();
    }
  }, [error]);

  return (
    <main>
      <LoadingContainer apiLoading={loading}>
        <ClientEdit onSubmit={onSubmit} client={selectedClient} />
      </LoadingContainer>
    </main>
  );
};
