import React, { FC } from 'react';
import styled from '@emotion/styled';
import { DetailModel } from 'services/models/detailModel';

type DetailItemProps = DetailModel & {
  onClickDeleteButton: () => void;
};

export const DetailItem: FC<DetailItemProps> = ({
  title,
  unitPrice,
  amount,
  unit,
  onClickDeleteButton,
}) => {
  return (
    <DetailCard>
      <DeleteButton onClick={onClickDeleteButton}>+</DeleteButton>
      <DetailTitle>{title}</DetailTitle>
      <SubTextWrap>
        <DetailUnit>
          {`${(unitPrice || 0).toLocaleString()} 円 x ${amount} ${unit}`}
        </DetailUnit>
        <DetailPrice>
          {`${((unitPrice || 0) * (amount || 0)).toLocaleString()} 円`}
        </DetailPrice>
      </SubTextWrap>
    </DetailCard>
  );
};

export const List = styled.ul`
  max-height: 300px;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const DetailCard = styled.li`
  background-color: #fff;
  border: 1px solid #707070;
  padding: 15px 15px 15px 37px;
  position: relative;

  & + & {
    margin: 15px 0 0;
  }
`;

const DetailTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
`;

const DetailUnit = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  margin: 0;
`;

const DetailPrice = styled.span`
  float: right;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  margin: 0;
`;

const SubTextWrap = styled.div`
  margin: 14px 0 0;
  overflow: hidden;
`;

const DeleteButton = styled.div`
  align-items: center;
  background-color: #e60a0a;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 20px;
  height: 22px;
  justify-content: center;
  left: 10px;
  padding-bottom: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 22px;
`;
