/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useEffect, useRef, useState } from 'react';
import { callFunctionName } from 'services/constants';
import { FirebaseContext } from 'contexts';
import { DetailModel } from 'services/models/detailModel';

export type PdfType = {
  documentType: 'quotation' | 'invoice';
  effectiveDate: number;
  title: string;
  deadline: number;
  note: string;
  detailList: DetailModel[];
  name: string;
  email: string;
  postCode: string;
  address: string;
  bankName: string;
  branchName: string;
  accountName: string;
  accountType: 'savings' | 'checking';
  accountNumber: string;
  clientName: string;
  clientPostCode: string;
  clientAddress: string;
  subTotal: number;
  salesTax: number;
  withholdingTax: number;
  total: number;
};

export const usePdf = (pdfId: string) => {
  const [downloadPdf, setDownLoadPdf] = useState<null | PdfType>(null);
  const [downloadPassword, setDownloadPassword] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const firebaseRef = useRef(useContext(FirebaseContext));

  useEffect(() => {
    if (pdfId && downloadPassword) {
      const { functions } = firebaseRef.current;
      if (!functions) throw new Error('Firestore is not initialized');
      const fetchDocumentOnCall = functions.httpsCallable(
        callFunctionName.fetchPdf,
      );
      const load = async () => {
        setLoading(true);
        try {
          const { data } = await fetchDocumentOnCall({
            id: pdfId,
            password: Number(downloadPassword),
          });
          setDownLoadPdf(data);
          setError(null);
        } catch (err) {
          setError(err);
        }
        setLoading(false);
      };

      load();
    }
  }, [pdfId, downloadPassword]);

  return { downloadPdf, setDownloadPassword, loading, error };
};
