import React from 'react';
import { FormControlProps, FormControlTypeMap } from '@material-ui/core';
import * as Presenter from './presenter';

export const FormControl = <
  D extends React.ElementType = FormControlTypeMap['defaultComponent'],
  P = {}
>(
  props: FormControlProps<D, P>,
) => {
  return <Presenter.FormControl {...props} />;
};
