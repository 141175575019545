import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Link, LinkProps } from 'react-router-dom';
import { Heading } from 'components/Typography';

export const ClientsHeading: FC = () => <Heading>クライアント一覧</Heading>;

export const ClientList: FC<{
  listItems: {
    id: string;
    clientName: string;
  }[];
}> = ({ listItems }) => {
  return (
    <ListUl>
      {listItems.map(({ id, clientName }) => (
        <li key={id}>
          {clientName}
          <ClientLink to={`/clients/edit-client/${id}`}>詳細 &gt;</ClientLink>
        </li>
      ))}
    </ListUl>
  );
};

export const AddClient: FC<LinkProps> = ({ children, ...props }) => (
  <AddClientWrapDiv>
    <AddClientLink {...props}>{children}</AddClientLink>
  </AddClientWrapDiv>
);

export const Main = styled.main`
  padding: 2rem 0;
`;

const AddClientLink = styled(Link)`
  background-color: #01b801;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  padding: 12px 25px;

  &:hover {
    color: #fff;
  }
`;

const AddClientWrapDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  padding: 0 20px;
  width: 100%;
`;

const ListUl = styled.ul`
  padding: 10px 20px;

  > li {
    font-size: 15px;
    line-height: 1.8;
    overflow: hidden;
    padding: 15px 0;
  }

  > li + li {
    border-top: 1px solid #ccc;
  }
`;

const ClientLink = styled(Link)`
  color: #005cc7;
  float: right;
  line-height: 1.8;
  position: relative;

  &::after {
    border-bottom: 1px solid #005cc7;
    bottom: 2px;
    content: '';
    display: inline-block;
    left: 0;
    position: absolute;
    width: 100%;
  }
`;
