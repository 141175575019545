import React, { FC, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { useClients } from 'hooks/useClients';
import { ClientSideClientModel } from 'services/models/clientModel';
import { ClientEdit } from 'components/ClientEdit';
import { DetailList } from 'components/DetailList';
import {
  FormControl,
  Checkbox,
  ThinTextFieldInput,
  ThinTextFieldWrap,
  TextFieldInput,
  TextFieldWrap,
  TextFieldArea,
  SelectWrap,
  SelectBox,
} from 'components/Form';
import { Modal } from 'components/Modal';
import { ClientSideDocumentModel } from 'services/models/documentModel';
import { DetailModel } from 'services/models/detailModel';
import { useDocumentData } from 'hooks/useDocument';
import { useTaxRates } from 'hooks/useTaxRates';
import { LoadingContainer } from 'components/LoadingContainer';
import * as Presenter from './presenter';
import { DetailForm } from './detailForm';

const calculateTotal = (
  subTotal: number,
  taxRate: number,
  taxable: boolean,
  withholding: boolean,
) => {
  const salesTax = taxable ? Math.trunc(subTotal * taxRate) : 0;
  const withholdingTax = (() => {
    if (!withholding) return 0;
    const withholdingTaxwWthFloot =
      subTotal <= 1000000 ? subTotal * 0.1021 : (subTotal - 1000000) * 0.2042;

    return Math.trunc(withholdingTaxwWthFloot);
  })();
  const total = subTotal + salesTax - withholdingTax;

  return {
    subTotal,
    salesTax,
    withholdingTax,
    total,
  };
};

type DocumentOutlineData = Pick<
  ClientSideDocumentModel,
  'title' | 'clientId' | 'note' | 'taxRate' | 'taxable' | 'withholding'
> & {
  deadline: string;
  effectiveDate: string;
};

export const EditDocument: FC<{
  documentId: string;
  initialDocumentType?: NonNullable<ClientSideDocumentModel['documentType']>;
}> = ({ documentId, initialDocumentType, children }) => {
  const {
    documentData,
    loading,
    error,
    registerDocumentData,
  } = useDocumentData(documentId);
  const {
    clientList,
    loading: clientLoading,
    error: clientError,
    registerClient,
    // deleteClient,
  } = useClients();
  const [isDetailDirty, setIsDetailDirty] = useState(false);
  const { taxRates, loading: taxLoading, error: taxError } = useTaxRates();
  const [open, setOpen] = useState<boolean>(false);
  const [openClient, setOpenClient] = useState<boolean>(false);
  const [detailList, setDetailList] = useState<DetailModel[]>([]);
  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    setValue,
    reset,
    formState,
  } = useForm<DocumentOutlineData>();
  const { isDirty } = formState;
  const title = watch('title');
  const clientId = watch('clientId');
  const isFilled = !!title && !!clientId && !!detailList.length;
  const notDirty = !(isDirty || isDetailDirty);
  const disabledButton = !isFilled || notDirty;
  const subTotal = detailList.reduce((a, c) => {
    const normalizationUnitPrice = c.unitPrice || 0;
    const normalizationAmount = c.amount || 0;

    const nextValue = a + normalizationUnitPrice * normalizationAmount;

    return nextValue;
  }, 0);
  const watchTaxable = watch('taxable');
  const { salesTax, withholdingTax, total } = calculateTotal(
    subTotal,
    watch('taxRate'),
    watchTaxable,
    watch('withholding'),
  );
  const deadlineText =
    documentData.documentType === 'invoice' || initialDocumentType === 'invoice'
      ? '請求日（お支払い期限）'
      : '見積もり書有効期限';

  const onSubmit: (data: DocumentOutlineData) => void = data => {
    const newData: ClientSideDocumentModel = {
      ...documentData,
      ...data,
      taxRate: Number(data.taxRate),
      effectiveDate: dayjs(data.effectiveDate).valueOf(),
      deadline: dayjs(data.deadline).valueOf(),
      documentType:
        documentData.documentType ?? initialDocumentType ?? 'invoice',
      detailList,
    };

    registerDocumentData(newData, {
      callback: window.liff.closeWindow,
    });
  };

  const handleClientSubmit: (
    client: ClientSideClientModel,
  ) => void = client => {
    registerClient({
      client,
      callback: (newClientList: ClientSideClientModel[]) => {
        setValue('clientId', newClientList.slice(-1)[0].id);
        setOpenClient(false);
      },
    });
  };
  const handleDetailSubmit: (data: DetailModel) => void = data => {
    setIsDetailDirty(true);
    setDetailList([...detailList, data]);
    setOpen(false);
  };

  const handleClickDeleteButton = (index: number): void => {
    setIsDetailDirty(true);
    const newList = detailList.filter((e, i) => index !== i);
    setDetailList(newList);
  };

  useEffect(() => {
    if (documentData) {
      reset({
        title: documentData.title,
        clientId: documentData.clientId,
        note: documentData.note,
        taxRate: documentData.taxRate,
        taxable: documentData.taxable,
        withholding: documentData.withholding,
        effectiveDate: dayjs(documentData.effectiveDate).format('YYYY-MM-DD'),
        deadline: dayjs(documentData.deadline).format('YYYY-MM-DD'),
      });

      setDetailList(documentData.detailList);
    }
  }, [setValue, documentData, reset]);

  useEffect(() => {
    /* eslint-disable no-alert */
    if (error) alert(error.message);
    if (clientError) alert(clientError.message);
    if (taxError) alert(taxError.message);
    /* eslint-enable no-alert */
    if (error || clientError || taxError) {
      window.liff.closeWindow();
    }
  }, [error, clientError, taxError]);

  useEffect(() => {
    const datePicker = document.querySelectorAll('.date-picker-input');

    datePicker.forEach(el => {
      if (el.setAttribute) el.setAttribute('readonly', 'readonly');
    });
  }, []);

  return (
    <Presenter.Main>
      <LoadingContainer apiLoading={loading || clientLoading || taxLoading}>
        {children}
        {!initialDocumentType && (
          <Presenter.LeadBlock
            text={documentData.documentType === 'invoice' ? '請求書' : '見積書'}
          />
        )}
        <form
          id="js-document-form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl component="fieldset" fullWidth>
            <ThinTextFieldWrap label="発行日">
              <ThinTextFieldInput
                type="date"
                name="effectiveDate"
                ref={register({
                  required: {
                    value: true,
                    message: '発行日を入力してください',
                  },
                })}
              />
            </ThinTextFieldWrap>
            <TextFieldWrap label="案件名" error={errors.title?.message}>
              <TextFieldInput
                name="title"
                placeholder="例：企業ロゴ制作費"
                maxLength={40}
                ref={register({
                  required: {
                    value: true,
                    message: '案件名を入力してください。',
                  },
                })}
              />
            </TextFieldWrap>
            <SelectWrap label="クライアント" error={errors.clientId?.message}>
              <Controller
                name="clientId"
                rules={{
                  required: {
                    value: true,
                    message: 'クライアントを入力してください。',
                  },
                }}
                render={({ value, onChange }) => (
                  <SelectBox
                    value={value}
                    onChange={e => onChange(e.target.value)}
                  >
                    <option value="">選択してください</option>
                    {clientList.map(client => (
                      <option value={client.id} key={client.id}>
                        {client.clientName}
                      </option>
                    ))}
                  </SelectBox>
                )}
                control={control}
              />
              <Presenter.AddClientText
                type="button"
                onClick={() => {
                  setOpenClient(true);
                }}
              >
                +新規追加
              </Presenter.AddClientText>
            </SelectWrap>
            <TextFieldWrap
              label={deadlineText}
              error={errors.deadline?.message}
            >
              <TextFieldInput
                type="date"
                name="deadline"
                ref={register({
                  required: {
                    value: true,
                    message: `${deadlineText}を入力してください。`,
                  },
                })}
              />
            </TextFieldWrap>
            <TextFieldWrap label="備考">
              <TextFieldArea
                name="note"
                placeholder="何かあれば入力してください"
                maxLength={200}
                ref={register}
              />
            </TextFieldWrap>
          </FormControl>
          <Presenter.DetailHeading>明細</Presenter.DetailHeading>
          <DetailList
            data={detailList}
            onClickDeleteButton={handleClickDeleteButton}
          />
          <Presenter.AddButton
            disabled={detailList.length >= 15}
            onClick={() => {
              setOpen(true);
            }}
          >
            ＋明細追加（最大15件まで）
          </Presenter.AddButton>
          <Presenter.TotalAmount
            subTotal={subTotal}
            salesTax={salesTax}
            withholdingTax={withholdingTax}
            total={total}
          />
          <Presenter.CheckboxWrap>
            <Presenter.TaxColumn>
              <Controller
                name="taxable"
                render={({ value, onChange }) => (
                  <Checkbox
                    color="primary"
                    label="課税"
                    checked={value}
                    onChange={event => {
                      onChange(event.target.checked);
                    }}
                  />
                )}
                control={control}
                defaultValue={documentData.taxable}
              />
              <Controller
                name="taxRate"
                render={({ value, onChange }) => (
                  <Presenter.TaxSelect
                    value={value}
                    onChange={e => onChange(Number(e.target.value))}
                    isShow={taxRates.length > 1 && watchTaxable}
                  >
                    {taxRates.map(taxRate => (
                      <option value={taxRate * 0.01} key={taxRate}>
                        {taxRate} %
                      </option>
                    ))}
                  </Presenter.TaxSelect>
                )}
                control={control}
              />
            </Presenter.TaxColumn>

            <Controller
              name="withholding"
              render={({ value, onChange }) => (
                <Checkbox
                  color="primary"
                  label="源泉徴収額を差し引く"
                  checked={value}
                  onChange={event => {
                    onChange(event.target.checked);
                  }}
                />
              )}
              control={control}
              defaultValue={documentData.withholding}
            />
          </Presenter.CheckboxWrap>
        </form>
        <Modal
          open={open}
          onClickClose={() => {
            setOpen(false);
          }}
        >
          <DetailForm
            handleDetailSubmit={handleDetailSubmit}
            typeText={
              documentData.documentType === 'invoice' ||
              initialDocumentType === 'invoice'
                ? '請求書'
                : '見積書'
            }
          />
        </Modal>
        <Modal
          open={openClient}
          onClickClose={() => {
            setOpenClient(false);
          }}
        >
          <LoadingContainer apiLoading={clientLoading}>
            <ClientEdit
              inline
              onSubmit={handleClientSubmit}
              client={{
                clientName: '',
                postCode: '',
                address: '',
              }}
            />
          </LoadingContainer>
        </Modal>

        <Presenter.Submit form="js-document-form" disabled={disabledButton}>
          {documentData.documentType === 'invoice' ||
          initialDocumentType === 'invoice'
            ? '請求書'
            : '見積書'}
          を発行
        </Presenter.Submit>
      </LoadingContainer>
    </Presenter.Main>
  );
};
