/**
 * Client Collectionを操作するhooks
 */

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useEffect, useRef, useState } from 'react';
import { ClientSideClientModel } from 'services/models/clientModel';
import { callFunctionName } from 'services/constants';
import { FirebaseContext, UserContext } from 'contexts';

export const useClients = (clientId?: string) => {
  const { user } = useContext(UserContext);
  const [clientList, setClientList] = useState<ClientSideClientModel[]>([]);
  const [selectedClient, setSelectedClient] = useState<ClientSideClientModel>({
    clientName: '',
    postCode: '',
    address: '',
  });
  const [registerTarget, registerClient] = useState<{
    client?: ClientSideClientModel;
    callback?: (newClient: ClientSideClientModel[]) => void;
  }>({});
  const [deleteTarget, deleteClient] = useState<{
    id?: ClientSideClientModel['id'];
    callback?: () => void;
  }>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const firebaseRef = useRef(useContext(FirebaseContext));

  // clientList
  useEffect(() => {
    if (!user) return;
    const { functions } = firebaseRef.current;
    if (!functions) throw new Error('Firestore is not initialized');

    const fetchClientOnCall = functions.httpsCallable(
      callFunctionName.fetchClientList,
    );
    const fetch = async () => {
      setLoading(true);
      try {
        const res = await fetchClientOnCall();
        const data = res.data as ClientSideClientModel[];
        const pickSelectedClient = data.filter(({ id }) => id === clientId)[0];
        if (pickSelectedClient) setSelectedClient(pickSelectedClient);
        setClientList(data);
        setError(null);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };
    fetch();
  }, [clientId, user]);

  // register
  useEffect(() => {
    if (!registerTarget.client) return;

    const { functions } = firebaseRef.current;
    if (!functions) throw new Error('Firestore is not initialized');

    const registerClientOnCall = functions.httpsCallable(
      callFunctionName.registerClient,
    );
    const register = async () => {
      setLoading(true);
      try {
        const res = await registerClientOnCall({
          client: registerTarget.client,
        });
        const result = res.data as ClientSideClientModel;
        const updatedFlag = clientList.some(({ id }) => id === result.id);
        const mergedValue = updatedFlag
          ? clientList.map(client => {
              if (client.id === result.id) {
                return result;
              }

              return client;
            })
          : [...clientList, result];
        if (registerTarget.callback) registerTarget.callback(mergedValue);
        registerClient({});
        setSelectedClient(result);
        setClientList(mergedValue);
        setError(null);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };
    register();
  }, [registerTarget, clientList]);

  // delete
  useEffect(() => {
    if (!deleteTarget.id) return;

    const { functions } = firebaseRef.current;
    if (!functions) throw new Error('Firestore is not initialized');

    const deleteClientOnCall = functions.httpsCallable(
      callFunctionName.deleteClient,
    );
    const executeDelete = async () => {
      setLoading(true);
      try {
        const res = await deleteClientOnCall({ id: deleteTarget.id });
        setSelectedClient({
          clientName: '',
          postCode: '',
          address: '',
        });
        setClientList(res.data as ClientSideClientModel[]);
        if (deleteTarget.callback) deleteTarget.callback();
        deleteClient({});
        setError(null);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };
    executeDelete();
  }, [deleteTarget]);

  return {
    selectedClient,
    clientList,
    loading,
    error,
    registerClient,
    deleteClient,
  };
};
