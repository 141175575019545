import styled from '@emotion/styled';

export const Heading = styled.h2`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

export const LeadParagraph = styled.p`
  font-size: 15px;
  line-height: 1.8;
  margin: 10px 0 0;
  text-align: center;
`;

export const AttentionText = styled.span`
  color: #c30505;
`;
