/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useEffect, useRef, useState } from 'react';
import { callFunctionName } from 'services/constants';
import { FirebaseContext } from 'contexts';

export const useTaxRates = () => {
  const [taxRates, setTaxRates] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const firebaseRef = useRef(useContext(FirebaseContext));

  useEffect(() => {
    const { functions } = firebaseRef.current;
    if (!functions) throw new Error('Firestore is not initialized');
    const fetchDocumentOnCall = functions.httpsCallable(
      callFunctionName.fetchTaxRates,
    );
    const load = async () => {
      setLoading(true);
      try {
        const { data }: { data: number[] } = await fetchDocumentOnCall();
        setTaxRates(data);
        setError(null);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };

    load();
  }, []);

  return { taxRates, loading, error };
};
