import React from 'react';
import styled from '@emotion/styled';

const Home: React.FC = () => (
  <main>
    <SplashImage />
  </main>
);

const SplashImage = styled.div`
  background-image: url('/images/nowLoading.png');
  background-size: cover;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1500;
`;

export default Home;
