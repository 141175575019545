import React, { FC, useEffect } from 'react';
import { useClients } from 'hooks/useClients';
import { LoadingContainer } from 'components/LoadingContainer';

import * as Presenter from './presenter';

export const Clients: FC = () => {
  const {
    clientList,
    loading: clientLoading,
    error: clientError,
  } = useClients();

  useEffect(() => {
    if (clientError) {
      // eslint-disable-next-line no-alert
      alert(clientError.message);
      window.liff.closeWindow();
    }
  }, [clientError]);

  const pickUpList = clientList.map(({ id, clientName }) => {
    const clientId = id as string;

    return {
      id: clientId,
      clientName,
    };
  });

  return (
    <LoadingContainer apiLoading={clientLoading}>
      <Presenter.Main>
        <Presenter.ClientsHeading />
        <Presenter.AddClient to="/clients/new-client">
          新規追加
        </Presenter.AddClient>
        <Presenter.ClientList listItems={pickUpList} />
      </Presenter.Main>
    </LoadingContainer>
  );
};
