import React, { FC } from 'react';
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core';

type CheckboxProps = MuiCheckboxProps & {
  label: string;
};

export const Checkbox: FC<CheckboxProps> = props => {
  const { label, ...otherProps } = props;

  return (
    <FormControlLabel control={<MuiCheckbox {...otherProps} />} label={label} />
  );
};
