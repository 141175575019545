/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';

type FirebaseContextValue = {
  auth: firebase.auth.Auth | null;
  db: firebase.firestore.Firestore | null;
  functions: firebase.functions.Functions | null;
};

export const FirebaseContext = createContext<FirebaseContextValue>({
  auth: null,
  db: null,
  functions: null,
});

type UserContextValue = {
  user: firebase.User | null;
};

export const UserContext = createContext<UserContextValue>({
  user: null,
});
