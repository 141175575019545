import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';

export const SubmitButton: FC<{ disabled?: boolean }> = ({
  children,
  disabled,
}) => (
  <SubmitButtonBtn
    type="submit"
    color="primary"
    size="large"
    variant="contained"
    disabled={disabled}
  >
    {children}
  </SubmitButtonBtn>
);

const SubmitButtonBtn = styled(Button)`
  &&& {
    display: block;
    font-weight: bold;
    margin: 2rem auto 0;
    width: 15rem;
  }
`;
