import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import App from './App';
import FirebaseApp from './FirebaseApp';
import firebaseConfig from './firebase-config';
import * as serviceWorker from './serviceWorker';

import './index.css';

const defaultTheme = createMuiTheme({
  typography: {
    fontSize: 16,
  },
  palette: {
    primary: {
      light: '#d2ffc5',
      main: '#01B801',
      dark: '#2c920d',
      contrastText: '#fff',
    },
  },
});

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <BrowserRouter>
    <FirebaseApp>
      <MuiThemeProvider theme={defaultTheme}>
        <App />
      </MuiThemeProvider>
    </FirebaseApp>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
