import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { DetailModel } from 'services/models/detailModel';
import { usePdf } from 'hooks/usePdf';
import { RouteComponentProps } from 'react-router-dom';
import html2canvas from 'html2canvas';
import JsPdf from 'jspdf';
import { LoadingContainer } from 'components/LoadingContainer';
import { FixedSubmitButton } from 'components/Button';
import * as Presenter from './presenter';

type PageProps = RouteComponentProps<{ id: string }>;

export const Pdf: FC<PageProps> = ({
  match: {
    params: { id },
  },
}) => {
  const { downloadPdf, loading, error, setDownloadPassword } = usePdf(id);
  const [creatingPdf, setCreatingPdf] = useState<boolean>(false);
  const [scale, setScale] = useState<number>(1);

  const [password, setPassword] = useState('');
  const [heightList, setHeightList] = useState<number[]>([]);
  const [footerHeight, setFooterHeight] = useState<number>(0);

  const onClickSendPassword = () => {
    setDownloadPassword(password);
  };

  const onClickDownload = async () => {
    setCreatingPdf(true);
    const page1 = document.getElementById('js-pdf-1');

    if (!page1) return;

    const doc = new JsPdf({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });
    const canvas = await html2canvas(page1, {
      windowWidth: 578,
      width: 578,
      height: 816,
      scale: 2,
    });
    const contentDataUrl = canvas.toDataURL('image/jpeg', 1.0);
    doc.addImage(contentDataUrl, 'JPEG', 0, 0, 210, 297);

    const page2 = document.getElementById('js-pdf-2');
    if (page2) {
      doc.addPage();
      const canvas2 = await html2canvas(page2, {
        windowWidth: 578,
        width: 578,
        height: 816,
        scale: 2,
      });
      const contentDataUrl2 = canvas2.toDataURL('image/jpeg', 1.0);
      doc.addImage(contentDataUrl2, 'JPEG', 0, 0, 210, 297);
    }
    doc.save();
    setCreatingPdf(false);
  };

  useEffect(() => {
    const itemElements = document.querySelectorAll<HTMLElement>('.js-items');
    const temp: number[] = [];
    itemElements.forEach(el => {
      temp.push(el.offsetHeight);
    });
    setHeightList(temp);

    const footer = document.querySelector<HTMLElement>('.js-footer');
    if (footer) setFooterHeight(footer.offsetHeight + 30);
  }, [downloadPdf, loading]);

  useEffect(() => {
    // eslint-disable-next-line no-alert
    if (error) alert(error.message);
  }, [error]);

  useEffect(() => {
    setScale(window.innerWidth / 580);
  }, []);

  if (!downloadPdf)
    return (
      <LoadingContainer apiLoading={loading || creatingPdf}>
        <Presenter.DownLoadEntry
          onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
          onButtonClick={onClickSendPassword}
        />
      </LoadingContainer>
    );

  const { detailList: items, ...restData } = downloadPdf;

  const firestPageDetail: DetailModel[] = [];
  const secondPageDetail: DetailModel[] = [];

  let totalHeight = footerHeight;
  heightList.forEach((height, index) => {
    if (totalHeight + height < 340) {
      totalHeight += height;
      firestPageDetail.push(items[index]);
    } else {
      secondPageDetail.push(items[index]);
    }
  });

  return (
    <LoadingContainer apiLoading={loading || creatingPdf}>
      <Presenter.Wrapper>
        <Presenter.ItemList items={items} getHeight />
        <Presenter.HiddenContainer>
          <Presenter.Pdf
            {...restData}
            firestPageDetail={firestPageDetail}
            secondPageDetail={secondPageDetail}
          />
        </Presenter.HiddenContainer>
        <Presenter.ScaleContainer
          scale={scale}
          maxPage={secondPageDetail.length ? 2 : 1}
        >
          <Presenter.Pdf
            {...restData}
            firestPageDetail={firestPageDetail}
            secondPageDetail={secondPageDetail}
          />
        </Presenter.ScaleContainer>
      </Presenter.Wrapper>
      <FixedSubmitButton onClick={onClickDownload} noHeight>
        PDFをダウンロード
      </FixedSubmitButton>
    </LoadingContainer>
  );
};
