import React, { FC } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import styled from '@emotion/styled';

type ModalProps = {
  open: boolean;
  onClickClose: () => void;
};

export const Modal: FC<ModalProps> = ({ children, open, onClickClose }) => (
  <Dialog open={open} fullScreen>
    <CloseBtn onClick={onClickClose} />
    <MyDialogContent>{children}</MyDialogContent>
  </Dialog>
);

const CloseBtn = styled(Close)`
  &&& {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 1;
  }
`;

const MyDialogContent = styled(DialogContent)`
  &&& {
    background-color: #fafafa;
    padding: 15px 30px;
  }
`;
