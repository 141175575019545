import styled from '@emotion/styled';

export const Pdf = styled.div`
  & * {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.6;
  }
  background-color: #fff;
  width: 580px;
`;

export const PdfPaper = styled.section`
  height: 820px;
`;

export const Hr = styled.hr`
  border-top: 2px solid;
  line-height: 1;
  margin: 0;
`;

export const PDFHeader = styled.div`
  padding: 30px 12px 10px;
  position: relative;
`;

export const PageNumber = styled.div`
  bottom: 13px;
  font-size: 14.5px;
  font-weight: bold;
  left: 28px;
  line-height: 1;
  position: absolute;
`;

export const Title = styled.div`
  display: flex;
  font-size: 21px;
  font-weight: bold;
  justify-content: center;
  letter-spacing: 6px;
  line-height: 1;
  margin-left: 7px;
`;

export const PublishDay = styled.div`
  bottom: 13px;
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  right: 18px;
`;

export const PdfInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 56px 0 0;
`;

export const Billing = styled.div`
  flex: 0 0 276px;
  position: relative;
`;

export const BillingAddress = styled.div`
  padding: 0 0 0 22px;
`;

export const BillingAddressText = styled.div`
  & + & {
    margin: 2px 0 0;
  }
  font-size: 10px;
  font-weight: bold;
`;

export const CompanyName = styled.span`
  font-size: 17px;
  font-weight: bold;
`;

export const BillingAssking = styled.div`
  font-size: 10px;
  font-weight: bold;
  margin: 10px 0 0;
`;
export const BillingPrice = styled.div`
  align-items: center;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  margin: 30px 0 0;
  padding: 16px 12px 16px 12px;
`;
export const BilligTextWrap = styled.div`
  flex: 0 0 80px;
`;
export const BillingPriceText = styled.div`
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  line-height: 1;
  text-align: center;

  & + & {
    margin: 3px 0 0;
  }
`;
export const BillingPriceYen = styled.div`
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  text-align: right;
`;
export const BillingDeadline = styled.div`
  border: 2px solid;
  border-left: 0;
  border-top: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 9px 14px 6px 20px;
  position: absolute;
  transform: translateY(100%);
  width: 100%;
`;
export const BillingDeadlinHeading = styled.div`
  font-size: 13px;
  font-weight: bold;
`;
export const BillingDeadlinDay = styled.div`
  font-size: 13px;
  font-weight: bold;
`;
export const Ordering = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 0 40px;
  padding: 10px 20px 6px 6px;
`;
export const OrderingAddress = styled.div``;
export const OrderingAddressText = styled.div`
  font-size: 10px;
  font-weight: bold;
  word-break: break-all;

  & + & {
    margin: 2px 0 0;
  }

  > span {
    display: inline-block;
  }
`;
export const PayeeAccount = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 6px 5px 12px;
`;
export const PayeeHeading = styled.div`
  flex: 0 0 auto;
  font-size: 10px;
  font-weight: bold;
`;
export const PayeeTextWrap = styled.div`
  margin: 0 0 0 15px;
`;
export const PayeeText = styled.div`
  font-size: 10px;
  font-weight: bold;
  text-align: right;

  & + & {
    margin: 1px 0 0;
  }
`;

export const PayeeBranch = styled.span`
  display: inline-block;
`;
export const PayeeNote = styled.div`
  margin: 50px 0 0;
  padding: 0 0 0 20px;
`;
export const PayeeNoteText = styled.div`
  color: #8d8d8d;
  font-size: 10px;
  font-weight: bold;
  width: 370px;
`;

export const WorkTitle = styled.div`
  border-bottom: 1px solid;
  margin: 20px 0 0;
  padding: 0 0 6px 20px;
  width: 370px;
`;
export const WorkTitleHeading = styled.span`
  font-size: 10px;
`;
export const WorkTitleText = styled.span`
  font-size: 10px;
  margin: 0 0 0 14px;
`;
export const TableWrap = styled.div`
  padding: 30px 18px 0;
  position: relative;
`;

export const TableHr = styled(Hr)`
  left: 0;
  position: absolute;
  top: 57px;
  width: 100%;
`;
export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  & > tbody {
    & > tr {
      & > th {
        font-size: 10px;
        font-weight: bold;
        min-width: 50px;
        padding: 6px 6px 10px 0;
        text-align: left;
        white-space: nowrap;
      }

      & > th:last-of-type {
        text-align: left;
      }
    }
  }
`;

export const HiddenItemList = styled.div`
  height: 0;
  overflow: hidden;
  width: 580px;
`;

export const ItemTr = styled.tr`
  border-bottom: 1px solid;

  & > td {
    font-size: 10px;
    line-height: 1.8;
    min-width: 60px;
    padding: 6px 6px 10px 0;
    text-align: left;
    vertical-align: bottom;
    white-space: nowrap;
  }

  & > td:first-of-type {
    padding: 6px 50px 10px 0;
    white-space: normal;
    width: 300px;
  }

  & > td:last-of-type {
    text-align: right;
  }
`;
export const Page2 = styled.div``;
export const Fotter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 0;
  padding: 0 24px 20px;
`;
export const Note = styled.div`
  flex: 0 0 340px;
`;
export const NoteHedding = styled.div`
  font-size: 10px;
  font-weight: bold;
`;
export const NoteText = styled.div`
  font-size: 10px;
  line-height: 1.8;
  margin: 2px 0 0;
`;
export const Price = styled.div`
  flex: 0 0 160px;
`;
export const PriceList = styled.div`
  display: flex;
  justify-content: space-between;

  > dt {
    font-size: 10px;
    font-weight: bold;
  }

  > dd {
    font-size: 10px;
    font-weight: bold;
  }
`;
