import React, { FC } from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from '@emotion/styled';

type LoadingProps = {
  apiLoading?: true;
};
export const Wrap: FC<LoadingProps> = ({ children, apiLoading }) => {
  return (
    <WrapDiv apiLoading={apiLoading}>
      {children}
      {apiLoading && (
        <ProgressWrap>
          <CircularProgress />
        </ProgressWrap>
      )}
    </WrapDiv>
  );
};

const WrapDiv = styled.div<LoadingProps>`
  ${({ apiLoading }) =>
    apiLoading &&
    `
    pointer-events: none;
    &:before {
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 5;
      content: '';
      position: fixed;
      width: 100%;
      height: 100%;
      display: block;
      pointer-events: none;
      background-color: #fff;
      opacity: 0.8;
    }
  `}
`;

const ProgressWrap = styled.div`
  &&& {
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
  }
`;
