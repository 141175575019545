import React, { FC, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { RouteComponentProps } from 'react-router-dom';
import { useDocumentList } from 'hooks/useDocumentList';
import { useClients } from 'hooks/useClients';
import { ClientSideDocumentModel } from 'services/models/documentModel';
import { LoadingContainer } from 'components/LoadingContainer';
import { ThinSelectBox, ThinSelectWrap } from 'components/Form';

import * as Presenter from './presenter';

type PageProps = RouteComponentProps<{
  documentType: NonNullable<ClientSideDocumentModel['documentType']>;
}>;

export const Documents: FC<PageProps> = ({
  match: {
    params: { documentType },
  },
}) => {
  const { documentList, loading, error } = useDocumentList(documentType);
  const {
    clientList,
    loading: clientLoading,
    error: clientError,
  } = useClients();
  const [createdYearList, setCreatedYearList] = useState<string[]>([]);
  const [filteringYear, setFilteringYear] = useState<string>('');

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<{ value: unknown }>) => {
    if (typeof value === 'string') {
      setFilteringYear(value);
    }
  };

  useEffect(() => {
    /* eslint-disable no-alert */
    if (error) alert(error.message);
    if (clientError) alert(clientError.message);
    /* eslint-enable no-alert */
    if (error || clientError) {
      window.liff.closeWindow();
    }
  }, [error, clientError]);

  useEffect(() => {
    const rowOptionList = documentList
      .map(({ effectiveDate }) => effectiveDate)
      .sort((a, b) => b - a)
      .map(date => dayjs(date).format('YYYY'));
    setCreatedYearList([...new Set(rowOptionList)]);
  }, [documentList]);

  useEffect(() => {
    setFilteringYear(createdYearList[0]);
  }, [createdYearList]);

  const filterdList = documentList
    .filter(
      ({ effectiveDate }) =>
        dayjs(effectiveDate).format('YYYY') === filteringYear,
    )
    .map(({ clientId, title, id }) => {
      const clientName = clientList.filter(client => client.id === clientId)[0]
        ?.clientName;
      const text = `${clientName} 様 | ${title}`;

      return {
        id,
        text,
      };
    });

  return (
    <LoadingContainer apiLoading={loading || clientLoading}>
      <Presenter.Main>
        <Presenter.DocumentsHeading />
        <Presenter.EffectiveYear>
          <ThinSelectWrap>
            <ThinSelectBox value={filteringYear} onChange={handleChange}>
              {createdYearList.map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </ThinSelectBox>
          </ThinSelectWrap>
        </Presenter.EffectiveYear>
        <Presenter.DocumentsTab documentType={documentType} />
        <Presenter.Hr />
        <Presenter.DocumentList listItems={filterdList} />
      </Presenter.Main>
    </LoadingContainer>
  );
};
