import React, { FC } from 'react';
import styled from '@emotion/styled';

import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps,
} from '@material-ui/core';

export const FormControlLabel: FC<FormControlLabelProps> = props => (
  <MyFormControlLabel {...props} />
);

const MyFormControlLabel = styled(MuiFormControlLabel)`
  .MuiTypography-body1 {
    color: #505050;
    font-size: 18px;
    font-weight: bold;
  }
`;
