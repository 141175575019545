import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ClientSideClientModel } from 'services/models/clientModel';
import { usePostCode } from 'hooks/usePostCode';
import { FormControl, TextFieldInput, TextFieldWrap } from 'components/Form';
import { FixedSubmitButton, SubmitButton } from 'components/Button';
import { Heading, LeadParagraph, AttentionText } from 'components/Typography';
import * as Presenter from './presenter';

type ClientEditProps = {
  inline?: true;
  client: ClientSideClientModel;
  onSubmit: (client: ClientSideClientModel) => void;
  onDelete?: () => void;
};

export const ClientEdit: FC<ClientEditProps> = ({
  inline,
  client,
  onSubmit,
  onDelete,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    formState,
  } = useForm<ClientSideClientModel>({
    defaultValues: {
      ...client,
    },
  });
  const { isDirty } = formState;
  const { addressFromPostCode, setPostCode } = usePostCode();
  const currentAdress = watch('address');

  useEffect(() => {
    if (!currentAdress) {
      setValue('address', addressFromPostCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, addressFromPostCode]);

  return (
    <Presenter.Wrap $inline={inline}>
      <Heading>クライアント情報入力</Heading>

      {onDelete ? (
        <Presenter.DeleteButton onClick={onDelete}>
          このクライアントを削除
        </Presenter.DeleteButton>
      ) : (
        <LeadParagraph>
          請求書を送付する企業の情報を
          <br />
          入力してください。
          <br />
          全項目<AttentionText>必須</AttentionText>になります。
        </LeadParagraph>
      )}

      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <FormControl component="fieldset" fullWidth>
          <TextFieldWrap label="会社名" error={errors.clientName?.message}>
            <TextFieldInput
              name="clientName"
              placeholder="テスト株式会社"
              maxLength={40}
              ref={register({
                required: {
                  value: true,
                  message: '会社名を入力してください。',
                },
              })}
            />
          </TextFieldWrap>
          <TextFieldWrap label="郵便番号" error={errors.postCode?.message}>
            <TextFieldInput
              name="postCode"
              placeholder="ハイフン不要"
              maxLength={7}
              inputMode="numeric"
              onChange={e => {
                setPostCode(e.target.value);
              }}
              ref={register({
                required: {
                  value: true,
                  message: '郵便番号を入力してください。',
                },
                pattern: {
                  value: /^\d{7}$/,
                  message: '半角数字7桁でを入力してください。',
                },
              })}
            />
          </TextFieldWrap>
          <TextFieldWrap label="住所" error={errors.address?.message}>
            <TextFieldInput
              name="address"
              maxLength={50}
              ref={register({
                required: {
                  value: true,
                  message: '住所を入力してください。',
                },
              })}
            />
          </TextFieldWrap>
        </FormControl>
        {inline && (
          <SubmitButton disabled={!isDirty}>
            {client.id ? '更新' : '登録'}
          </SubmitButton>
        )}
        {!inline && (
          <FixedSubmitButton disabled={!isDirty}>
            {client.id ? '更新' : '登録'}
          </FixedSubmitButton>
        )}
      </form>
    </Presenter.Wrap>
  );
};
